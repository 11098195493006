<template>
  <main>
    <div class="text-center">
      <v-card>
        <v-card-title>
          Zonas de Desembarco
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn
            small
            @click="openOrder"
            color="blue-grey  darken-4 white--text"
            dark
            class="mb-2 ml-3"
            v-if="$can('editarZonaDeDesembarco')"
            ><v-icon size="medium" class="mr-1">
              mdi-arrow-split-horizontal
            </v-icon>
            Ordenar lista
          </v-btn>

          <v-dialog v-model="dialog" width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="indigo darken-4 white--text"
                dark
                class="mb-2 ml-3"
                v-bind="attrs"
                v-on="on"
                v-if="$can('agregarZonaDeDesembarco')"
              >
                <v-icon left small>fa-plus</v-icon>Nuevo
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline indigo darken-4 white--text">
                Zonas de desembarco
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" lg="6" md="6">
                        <v-text-field
                          v-model="port_of_landing.name"
                          label="Nombre"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="12" lg="6" md="6">
                        <v-text-field
                          v-model="port_of_landing.latitude"
                          label="Latitud"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="12" lg="6" md="6">
                        <v-text-field
                          v-model="port_of_landing.longitude"
                          label="Longitud"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="12" lg="6" md="6">
                        <v-select
                          :value="states"
                          v-model="port_of_landing.state"
                          :items="states"
                          label="Estado"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false"
                  >Cerrar</v-btn
                >
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [5,10,15]
          }"
          :headers="headers"
          :items="portOfLanding"
          :search="search"
          item-key="id"
        >
          <template v-slot:item.action="{ item }">
            <v-menu left bottom  v-if="$can('editarZonaDeDesembarco')">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="show(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small></v-icon>Editar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div></div>
    <modal
      :maxWidth="1200"
      :persistent="true"
      ref="modal"
      title="Ordemar puertos de desembarco"
    >
      <template #default>
        <sortable-items
          :items="itemsLanding"
          @onDrop="dropItems"
        ></sortable-items>
      </template>
    </modal>
  </main>
</template>
<script>
import swal from "sweetalert";
import {
  savePortOfLandings,
  getPortOfLandingsAll,
  showPortOfLandingById,
  updatePortOfLandings,
  updateOrderPortOfLandings,
} from "../../services/portOfLandingService";
import Modal from "../../components/Modal.vue";
import SortableItems from "../../components/SortableItems.vue";
export default {
  components: { Modal, SortableItems },
  data() {
    return {
      menu: false,
      modal: false,
      menu2: false,
      dialog: false,
      form_mode: "store",
      portOfLanding: [],
      port_of_landing: {
        id: "",
        name: "",
        latitude: "",
        longitude: "",
        state: "ACTIVE",
      },

      states: ["ACTIVE", "INACTIVE"],
      search: "",
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Latitud", value: "latitude" },
        { text: "Longitud", value: "longitude" },
        { text: "Acción", value: "action", width: "25%" },
      ],
    };
  },
  computed: {
    itemsLanding() {
      return this.portOfLanding.map((x) => ({ ...x, name: x.nombre }));
    },
  },
  methods: {
    async save() {
      try {
        if (this.form_mode === "store") {
          await savePortOfLandings(this.port_of_landing);
          await swal("Exito!", "Datos guardados!", "success");
          this.getPortOfLanding();
          this.form_mode = "store";
          this.cleanData();
        } else if (this.form_mode === "update") {
          await updatePortOfLandings(this.port_of_landing);
          await swal("Exito!", "Datos actualizados!", "success");
          this.getPortOfLanding();
          this.form_mode = "store";
          this.dialog = false;
          this.cleanData();
        }
      } catch (err) {
        if (err.response.status == 422) {
          const obj_erros = Object.values(err.response.data.errors);
          obj_erros.forEach((err) => {
            this.$toasted.show(`Error ${Array.isArray(err) ? err[0] : err}`, {
              theme: "bubble",
              position: "bottom-right",
              duration: 4000,
              action: {
                text: "",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            });
          });
        }
      }
    },
    async getPortOfLanding() {
      const response = await getPortOfLandingsAll();
      this.portOfLanding = response.data;
    },
    async show(id) {
      this.form_mode = "update";
      const response = await showPortOfLandingById(id);
      this.port_of_landing.id = response.data.id;
      this.port_of_landing.latitude = response.data.latitude;
      this.port_of_landing.name = response.data.nombre;
      this.port_of_landing.state = response.data.state;
      this.port_of_landing.longitude = response.data.longitude;
      this.dialog = true;
    },
    cleanData() {
      this.port_of_landing = {};
    },
    openOrder() {
      this.$refs.modal.openModal();
    },
    async dropItems(items) {
      await updateOrderPortOfLandings({ ports: items });
      this.portOfLanding = items;
    },
  },
  created() {
    this.getPortOfLanding();
  },
};
</script>
