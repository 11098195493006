<template>
  <div>
    <v-card class="mx-auto"   max-width="800">
      <v-card-title >Permisos del sistema
            <v-spacer></v-spacer>
    <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-btn
          color="indigo darken-4 white--text"
          dark
          class="mb-2 ml-3"
          @click="openDialog"
          v-if="$can('editarPermisos') && validar"
        >
         <v-icon left small>fa-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
   
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :headers="headers"
        :items="allPermissions"
        class="elevation-1"
         :search="search"
      ></v-data-table>
      <modal
        ref="modalDialog"
        :maxWidth="600"
        :persistent="true"
        title="Nuevo Permiso"
      >
        <template #default>
          <form @submit.prevent="save()" class="py-5">
            <v-col class="d-flex" cols="12" sm="12" lg="12" md="12">
              <v-text-field
                v-model="permission.name"
                label="Nombre"
                required
              ></v-text-field>
            </v-col>
            <div class="d-flex mx-3 justify-end">
              <v-btn type="submit" color="success" elevation="2">Guardar</v-btn>
            </div>
          </form>
        </template>
      </modal>
    </v-card>
  </div>
</template>

<script>
import permissionService from "../../../services/permissionService";
import Swal from "sweetalert";
import Modal from "../../../components/Modal.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    Modal,
  },
  computed: {
    ...mapGetters(['allPermissions'])
  },
  data() {
    return {
      headers: [ {text: 'Nombre',align: 'start', sortable: false,value: 'name' },],
      search: '',
      validar: false,
      permission: {
        name: "",
        guard_name: "web",
      },
    };
  },
  methods: {
    ...mapActions(['getPermissions']),
    async save() {
      try {
        if (!this.permission.name) {
          return; 
        }
        this.$showLoading();
        await permissionService.createPermission(this.permission);
        this.resetForm();
        this.closeDialog();
        this.getPermissions();
        this.$hideLoading();
        Swal.fire("Exito", "Permiso creado con exito", "success");
      } catch (e) {
        this.$hideLoading();
        Swal.fire(
          "Ups!",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    openDialog(){
      this.$refs.modalDialog.openModal();
    },
    closeDialog(){
       this.$refs.modalDialog.closeModal();
    },
    resetForm(){
      this.permission.name = '';
    }
  },
  async created(){
    try {
      this.$showLoading();
      await this.getPermissions();
      this.$hideLoading();
    } catch (error) {
      this.$hideLoading()
    }
  }
};
</script>
