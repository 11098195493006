import BaseService from "./BaseService";

const api = "fishing-arts-boats";

export function getFishingArtsBoatsAll() {
    return BaseService.get(`${api}`);
}

export function showFishingArtsBoatsById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function saveFishingArtsBoats(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updateFishingArtsBoats(obj) {
    return BaseService.put(`${api}/update`, obj);
}