import BaseService from "./BaseService";

const api = "portOfLanding";

export function getPortOfLandingsAll() {
    return BaseService.get(`${api}`);
}

export function showPortOfLandingById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function savePortOfLandings(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updatePortOfLandings(obj) {
    return BaseService.put(`${api}/update`, obj);
}
export function updateOrderPortOfLandings(array) {
    return BaseService.put(`${api}/update/order`, array);
}