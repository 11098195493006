import BaseService from "./BaseService";

const api = "registration-port";

export function getRegistrationPortAll() {
    return BaseService.get(`${api}`);
}

export function showRegistrationPortById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function saveRegistrationPort(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updateRegistrationPort(obj) {
    return BaseService.put(`${api}/update`, obj);
}