<template>
<main>
  <div class="text-center">
    <v-card>
      <v-card-title>
        Artes de pesca (permiso de pesca, pescadores) 
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-dialog v-model="dialog"  width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="indigo darken-4 white--text"
          dark
          class="mb-2 ml-3"
          v-bind="attrs"
          v-on="on"
        >
         <v-icon left small>fa-plus</v-icon>Nuevo
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text">
          Botes de Arte de Pesca
        </v-card-title>
        <v-card-text>
          <v-form >
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                  >
                  <v-text-field
                    v-model="fishing_arts_boats.nombre"
                    label="Nombre"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false;"
            >Cerrar</v-btn
          >
          <v-btn color="blue darken-1" text @click="save"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-card-title>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :headers="headers"
        :items="fishing_arts_boats_all"
        :search="search"
        item-key="id"
      >
      <template v-slot:item.action="{ item }">
          <!--v-icon small @click="deleteItem(item)">delete</v-icon-->
          <!--v-icon small @click="viewItem(item)" class="mr-2">search</v-icon-->
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="show(item.id)">
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>Editar
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="Desactive(item)">
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>Desactivar
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <div>
  </div>
  </main>
</template>
<script>
import swal from "sweetalert";
import { saveFishingArtsBoats,getFishingArtsBoatsAll,showFishingArtsBoatsById,updateFishingArtsBoats} from "../../services/fishingArtsBoatsService";
export default {
    data(){
        return{ 
            menu: false,
            modal: false,
            menu2: false,
            dialog: false,
            
            form_mode: 'store',
            fishing_arts_boats_all:[],
            fishing_arts_boats:{
                id: '',
                nombre:'',
                codigo:'',
            },

            search: '',
            headers: [
                {text: 'Codigo',align: 'start',sortable: false,value: 'codigo', },
                { text: 'Nombre', value: 'nombre' },
                { text: "Acción", value: "action", width: "25%" },
            ],
        }
    },
    methods:{
        async save(){
          try {
            if(this.form_mode==='store'){
              await saveFishingArtsBoats(this.fishing_arts_boats);
              await swal("Exito!", "Datos guardados!", "success");
              this.getFishingArtsBoats();
              this.form_mode = 'store';
              this.cleanData();
            }else if(this.form_mode==='update'){
              await updateFishingArtsBoats(this.fishing_arts_boats);
              await swal("Exito!", "Datos actualizados!", "success");
              this.getFishingArtsBoats();
              this.form_mode = 'store';
              this.dialog=false;
              this.cleanData();
            }
          } catch (err) {
            if (err.response.status == 422) {
              const obj_erros = Object.values(err.response.data.errors);
              obj_erros.forEach((err) => {
                this.$toasted.show(`Error ${Array.isArray(err) ? err[0] : err}`, {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: 4000,
                  action: {
                    text: "",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    },
                  },
                });
              });
            }
          }
        },
        async getFishingArtsBoats(){
            const response = await getFishingArtsBoatsAll();
            this.fishing_arts_boats_all = response.data;
        },
        async show(id){
            this.form_mode='update';
            const response = await showFishingArtsBoatsById(id);
            this.fishing_arts_boats.id = response.data.id;
            this.fishing_arts_boats.codigo = response.data.codigo;
            this.fishing_arts_boats.nombre = response.data.nombre;
            this.dialog=true;
        },
        cleanData(){
          this.fishing_arts_boats={};
        }
    },
    created() {
        this.getFishingArtsBoats();
    } 
}
</script>
