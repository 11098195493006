<template>
  <div>
    <div class="simple-page">
      <v-row>
        <v-col cols="12">
            <v-card >
          <Container @drop="onDrop">

              <Draggable v-for="(item, i) in mutableList" :key="item.id">
                <div class="draggable-item col-12 list d-flex" style="justify-content: space-between;">
                 {{ item.name }}  <span> <strong class="mr-4"># {{i+1}}</strong> <v-icon>mdi-arrow-split-horizontal</v-icon> </span>
                </div>
              </Draggable>
          </Container>

            </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
import { applyDrag } from "../utils/helpers";
export default {
  props: ["items"],
  components: { Container, Draggable },
  data(){
   return {
      mutableList: this.items
   }
  },
  methods: {
    onDrop(dropResult) {
      this.mutableList = applyDrag(this.items, dropResult);
      const arrayOrder = this.mutableList.map((x, i) => ({...x, order: i+1}))
      this.$emit('onDrop', arrayOrder);
    },
  },
};
</script>

<style  scoped>
.list {
  border-bottom: 1px solid;
  cursor:pointer;
}
</style>
