<template>
<main>
  <div class="text-center">
    <v-card>
      <v-card-title>
        Plantillas de Texto
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-dialog v-model="dialog"  width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="indigo darken-4 white--text"
          dark
          class="mb-2 ml-3"
          v-bind="attrs"
          v-on="on"
        >
         <v-icon left small>fa-plus</v-icon>Nuevo
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text">
         Plantilla
        </v-card-title>
        <v-card-text>
          <v-form >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  lg="12"
                  md="6"
                >
               
                  <v-subheader>Texto</v-subheader>
                  <ckeditor
                    :editor="editor"
                    v-model="text_template.text"
                    :config="editorConfig"
                  ></ckeditor>
               
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  lg="12"
                  md="6"
                >
                  <v-text-field
                    v-model="text_template.document_type"
                    label="Tipo de Documento"
                    required
                  ></v-text-field>
                </v-col>
               
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false;"
            >Cerrar</v-btn
          >
          <v-btn color="blue darken-1" text @click="save"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-card-title>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :headers="headers"
        :items="text_templates"
        :search="search"
        item-key="id"
      >
      <template v-slot:item.action="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="show(item.id)">
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>Editar
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="Desactive(item)">
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>Desactivar
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      <template v-slot:item.text="{ item }">
        <p v-html="item.text" class="template-html"></p>
      </template>
      </v-data-table>
    </v-card>
  </div>
  <div>
  </div>
  </main>
</template>
<script>

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import swal from "sweetalert";
import { saveTextTemplates,getTextTemplatesAll,showTextTemplateById,updateTextTemplates} from "../../services/textTemplateService";

export default {
    data(){
        return{ 
            menu: false,
            modal: false,
            menu2: false,
            dialog: false,
            
            form_mode: 'store',
            text_templates:[],
            text_template:{
                id: '',
                document_type:'',
                text:'',
            },

            editor: ClassicEditor,
            editorData: "",
            editorConfig: {
              toolbar: {
                items: ["bold", "italic", "link", "undo", "redo"],
              },
            },

           
          
            search: '',
            headers: [
                {text: 'Texto',align: 'start',value: 'text' },
                { text: 'Tipo Documento', value: 'document_type' },
                { text: "Acción", value: "action", width: "25%" },
            ],
        }
    },
    methods:{
        async save(){
          try {
            if(this.form_mode==='store'){
              await saveTextTemplates(this.text_template);
              await swal("Exito!", "Datos guardados!", "success");
              this.getTextTemplate();
              this.form_mode = 'store';
              this.cleanData();
            }else if(this.form_mode==='update'){
              await updateTextTemplates(this.text_template);
              await swal("Exito!", "Datos actualizados!", "success");
              this.getTextTemplate();
              this.form_mode = 'store';
              this.dialog=false;
              this.cleanData();
            }
          } catch (err) {
            if (err.response.status == 422) {
              const obj_erros = Object.values(err.response.data.errors);
              obj_erros.forEach((err) => {
                this.$toasted.show(`Error ${Array.isArray(err) ? err[0] : err}`, {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: 4000,
                  action: {
                    text: "",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    },
                  },
                });
              });
            }
          }
        },
        async getTextTemplate(){
            const response = await getTextTemplatesAll();
            this.text_templates = response.data;
        },
        async show(id){
            this.form_mode='update';
            const response = await showTextTemplateById(id);
            this.text_template.id = response.data.id;
            this.text_template.text = response.data.text;
            this.text_template.document_type = response.data.document_type;
            this.dialog=true;
        },
        cleanData(){
          this.text_template={};
        }
    }, 
    created() {
      this.getTextTemplate();
    }
}
</script>
<style scoped>
.template-html {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>