import BaseService from "./BaseService";

const api = "fishingArt";

export function getFishingArtsAll() {
    return BaseService.get(`${api}`);
}

export function showFishingArtById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function saveFishingArts(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updateFishingArts(obj) {
    return BaseService.put(`${api}/update`, obj);
}