import BaseService from "./BaseService";

const api = "types-of-fihsery";

export function getTypesOfFihseriesAll() {
    return BaseService.get(`${api}`);
}

export function showTypesOfFihseryById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function saveTypesOfFihsery(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updateTypesOfFihsery(obj) {
    return BaseService.put(`${api}/update`, obj);
}