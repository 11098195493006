import BaseService from "./BaseService";

const api = "textTemplate";

export function getTextTemplatesAll() {
    return BaseService.get(`${api}`);
}

export function showTextTemplateById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function saveTextTemplates(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updateTextTemplates(obj) {
    return BaseService.put(`${api}/update`, obj);
}