<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="fullscreen"
      :persistent="persistent"
      :max-width="maxWidth"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="indigo darken-4">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <slot name="default"></slot>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["fullscreen", "persistent", "maxWidth", "title"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
