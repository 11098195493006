import BaseService from "./BaseService";

const api = "authorized-arts";

export function getAuthorizedArtsAll() {
    return BaseService.get(`${api}`);
}

export function showAuthorizedArtsById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function saveAuthorizedArts(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updateAuthorizedArts(obj) {
    return BaseService.put(`${api}/update`, obj);
}