<template>
  <div>
    <v-card>
  
      <form @submit.prevent="submit" enctype="multipart/form-data">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :value="setting.descripcion"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6"  >
              <v-file-input
                label="Imagen de la Firma"
                v-if="type == 'firma_secretario'"
                :disabled="!$can('modificarSalario')"
                @change="loadImage"
              >
              </v-file-input>

              <v-text-field
                v-else-if="type == 'smldv'"
                v-model="valor"
                label="Valor"
                type="number"
                :disabled="!$can('modificarSalario')"
              >
              </v-text-field>

              <v-text-field
                v-else
                v-model="valor"
                label="Nombre"
                :disabled="!$can('modificarNombreSecretario')"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="type == 'firma_secretario'"
              class="d-flex justify-center"
              cols="12"
              md="12"
            >
              <v-avatar
                class="profile ma-4"
                color="grey"
                style="min-width: 400px; height: 100"
                tile
              >
                <v-img :src="path + valor"></v-img>
              </v-avatar>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                text
                type="submit"
                class="indigo darken-4 white--text"
                small
                v-if="type=== 'smldv' ? 
                $can('modificarSalario') : type === 'firma_secretario' ? 
                $can('modificarFirma') : 
                $can('modificarNombreSecretario')"
              >
                <v-icon>done_all</v-icon>&nbsp;Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </v-card>
  </div>
</template>

<script>
import { saveSetting, getHeader, firmaUrl } from "../config";

export default {
  props: ["type"],
  data: () => ({
    valor: "",
    path: firmaUrl,
  }),
  computed: {
    setting() {
      let settings = { nombre: "", descripcion: "", valor: "" };
      while (this.$store.state.settings.length != 0) {
        settings = this.$store.state.settings.find(
          (x) => x.nombre == this.type
        );
        break;
      }

      return settings;
    },
  },
  watch: {
    type(newVal, oldVal) {
      if (newVal != oldVal) {
        this.valor = this.setting.valor;
      }
    },
  },
  created() {
    this.valor = this.setting.valor;
  },
  methods: {
    submit() {
      this.axios
        .put(
          saveSetting,
          { nombre: this.type, valor: this.valor },
          { headers: getHeader() }
        )
        .then((response) => {
          this.$store.commit("SETTINGS", response.data);
        });
    },

    loadImage(file) {
      let reader = new FileReader();

      reader.onload = (e) => {
        this.valor = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>
