<template>
  <div>
    <v-card class="mx-auto" max-width="800">
      <v-card-title
        >Roles del sistema
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-btn
          color="indigo darken-4 white--text"
          dark
          class="mb-2 ml-3"
          @click="openDialogCreate"
          v-if="$can('crearRoles')"
        >
          <v-icon left small>fa-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>

      <v-data-table :footer-props="{
        'items-per-page-options': [5,10,15]
      }" :headers="headers" :items="allRoles" class="elevation-1">
        <template v-slot:item.action="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="show(item)" v-if="$can('editarRoles')">
                <v-list-item-title>
                  <v-icon class="mr-2" small>fas fa-edit</v-icon> Editar
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="removeRole(item)"  v-if="$can('eliminarRoles')">
                <v-list-item-title>
                  <v-icon class="mr-2" small>fas fa-trash</v-icon> Eliminar
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <modal
      :maxWidth="900"
      :persistent="true"
      title="Asignar permisos"
      ref="modalDialog"
    >
      <template #default>
        <div class="py-5">
          <v-col class="d-flex" cols="12" sm="12" lg="12" md="12">
            <v-text-field
              v-model="role.name"
              label="Nombre"
              required
            ></v-text-field>
          </v-col>
          <role-permission
            :ownerName="role.name"
            ref="rolePermission"
            @onDrop="setPermissions"
          ></role-permission>
        </div>
      </template>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn @click="save()" color="success" elevation="2">Guardar</v-btn>
      </template>
    </modal>
  </div>
</template>

<script>
import permissionService from "../../../services/permissionService";
import swal from "sweetalert";
import Modal from "../../../components/Modal.vue";
import RolePermision from "../../../components/role-permission";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Modal,
    "role-permission": RolePermision,
  },
  computed: {
    ...mapGetters(["allRoles", "allPermissions"]),
  },
  data() {
    return {
      headers: [
        { text: "Nombre", align: "start", sortable: false, value: "name" },
        { text: "", value: "action", width: "25%" },
      ],
      search: "",
      role: {
        name: "",
        guard_name: "web",
      },
      currentPermissions: [],
    };
  },
  methods: {
    ...mapActions(["getRoles", "getPermissions", "getPermissionsByRole"]),
    setPermissions(permissions){
      this.currentPermissions = permissions.map(x => x.name);
    },
    async save() {
      try {
        if (!this.role.name) {
          return;
        }
        this.$showLoading();
        let methodDescription = "creado";
        if (!this.role.update) {
          await permissionService.storeRole({...this.role, permissions: this.currentPermissions});
        } else {
          await permissionService.updateRole(this.role);
          await permissionService.syncPermissionByRole(this.role, {permissions: this.currentPermissions});
          methodDescription = "actualizado";
        }
        this.resetForm();
        this.closeDialog();
        this.getRoles();
        this.$hideLoading();
        this.$toasted.success(`Rol ${methodDescription} con exito`, {
          position: "top-right",
          className: "toast-rgp",
          duration: 5000,
        });
      } catch (e) {
        this.$hideLoading();
        swal("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
      }
    },
    async show(item) {
      this.role = { ...item, update: true };
      await this.getPermissionsByRole(this.role);
      this.openDialog();
    },
    async removeRole(item) {
      const value = await swal("Seguro desea continuar", {
        buttons: {
          cancel: "Cancelar",
          Si: true,
        },
      });
      if (value === "Si") {
        try {
          await permissionService.removeRole(item);
          this.$toasted.success("Rol eliminado con exito", {
            position: "top-right",
            duration: 5000,
          });
        } catch (error) {
          this.$toasted.error("Ups, ocurrió un error", {
            position: "top-right",
            duration: 5000,
          });
        }
      }
    },
    openDialogCreate(){
      this.openDialog();
      this.resetForm();
    },
    openDialog() {
      this.$refs.modalDialog.openModal();
      this.$nextTick(function () {
         this.$refs.rolePermission.setPayload();
      });
    },
    closeDialog() {
      this.$refs.modalDialog.closeModal();
    },
    resetForm() {
      this.role = {};
    },
  },
  async created() {
    try {
      this.$showLoading();
      await this.getRoles();
      await this.getPermissions();
      this.$hideLoading();
    } catch (error) {
      this.$hideLoading();
    }
  },
};
</script>
