<template>
  <v-card>
    <v-toolbar flat color="indigo darken-4" dark>
      <v-toolbar-title>Configuración General</v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical>
      <v-subheader>Embarcaciones</v-subheader>
      <v-divider></v-divider>
      <v-tab>
        <v-icon left size="medium"> mdi-docker </v-icon>
        Zona desembarco
      </v-tab>
      <v-tab>
        <v-icon left size="medium"> mdi-fish </v-icon>
        Arte de pesca
      </v-tab>
      <v-tab>
        <v-icon left size="medium"> mdi-clipboard-edit-outline </v-icon>
        Puertos de registro
      </v-tab>
      <v-subheader>Permiso de pesca</v-subheader>
      <v-divider></v-divider>

      <v-tab>
        <v-icon left size="medium"> mdi-format-list-bulleted-type </v-icon>
        Tipos de pesquería
      </v-tab>
      <v-tab>
        <v-icon left size="medium"> mdi-ferry </v-icon>
        Artes de pesca
      </v-tab>
      <v-tab>
        <v-icon left size="medium"> mdi-check-underline-circle </v-icon>
        Artes autorizadas
      </v-tab>

      <v-subheader>Formatos</v-subheader>
      <v-divider></v-divider>
      <v-tab>
        <v-icon left size="medium"> mdi-note-plus </v-icon>
        plantillas de Texto
      </v-tab>
      <v-subheader>Más ajustes</v-subheader>
      <v-divider></v-divider>
      <v-tab>
        <v-icon left size="medium"> mdi-currency-usd </v-icon>
        SMDLV
      </v-tab>
      <v-tab>
        <v-icon left size="medium"> mdi-rename-box </v-icon>
        Firma secretario
      </v-tab>
      <v-tab>
        <v-icon left size="medium"> mdi-form-textbox </v-icon>
        Nombre secretario
      </v-tab>
      <v-subheader>Sistema</v-subheader>
      <v-divider></v-divider>
      <v-tab v-if="$can('verRoles')">
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Roles
      </v-tab>
      <v-tab v-if="$can('verPermisos')">
        <v-icon left size="medium">fas fa-user-check</v-icon>
        Permisos
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <createportoflanding></createportoflanding>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <fishingart></fishingart>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <registrationPort></registrationPort>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <typesOfFihsery></typesOfFihsery>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <fishingArtsPermit></fishingArtsPermit>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <authorizedArts></authorizedArts>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <texttemplate></texttemplate>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-for="config in settings" :key="config.nombre">
        <v-card flat>
          <v-card-text>
            <settings :type="config.nombre"></settings>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="$can('verRoles')">
        <v-card flat>
          <v-card-text>
            <roles></roles>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="$can('verPermisos')">
        <v-card flat>
          <v-card-text>
            <permissions></permissions>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import createportoflanding from "../port-of-landing/Create";
import fishingart from "../fishing-art/Create";
import settings from "../settings";
import texttemplate from "../text-template/Create";
import Permissions from "../rgp-permissions/permissions";
import Roles from "../rgp-permissions/roles";
import typesOfFihsery from "../types-of-fihsery/Create";
import fishingArtsPermit from "../fishing-arts-permit/Create.vue";
import authorizedArts from "../authorized-arts/Create";
import registrationPort from "../registration-port/Create";
import { getConfig, getHeader } from "../../config";

export default {
  components: {
    createportoflanding,
    fishingart,
    settings,
    texttemplate,
    typesOfFihsery,
    fishingArtsPermit,
    authorizedArts,
    registrationPort,
    Permissions,
    Roles,
  },
  data() {
    return {
      settings: [
        { nombre: "smldv" },
        { nombre: "firma_secretario" },
        { nombre: "nombre_secretario" },
      ],
    };
  },
  computed: {
    username() {
      return JSON.parse(localStorage.getItem("authUser"));
    },
  },

  beforeMount() {
    if (this.username.rol_id <= 2) {
      this.axios.get(getConfig, { headers: getHeader() }).then((response) => {
        this.$store.commit("SETTINGS", response.data);
      });
    }
  },
};
</script>
<style scoped>
.v-tab {
  justify-content: start !important;
}
</style>
